<template>
    <SectionsNav>
        <template #introduction>
            <a class="nav-link" @click="showSection('presentation')">Обзор</a>
        </template>
        <template #programs>
            <a class="nav-link" @click="showSection('programs')">Программы</a>
        </template>
        <template #campuses>
            <a class="nav-link" @click="showSection('campus')">Кампусы</a>
        </template>
        <template #selectSection>
            <select id="select-mobile-menu" class="form-select form-select-lg" v-model="this.currentSection">
                <option value="presentation">Обзор</option>
                <option value="programs">Программы</option>
                <option value="campus">Кампусы</option>
            </select>
        </template>
    </SectionsNav>
    
    <Obzor v-if="currentSection === 'presentation'" ref="presentation" class="presentation">
        <template #title>
            Обзор
        </template>
        <template #description> 
            Кулинарная школа Le Cordon Bleu основана в Париже в 1895 году. На сегодняшний день это крупнейшая сеть кулинарных школ и учреждений гостеприимства в мире. Школа имеет более 35 филиалов в 20 странах, где ежегодно проходят обучение 20 000 студентов более 100 национальностей. Le Cordon Bleu сочетает новаторство и творчество с традициями. Школа предлагает различные программы с присвоением сертификатов, дипломов, степени бакалавра и магистра. 
        </template>
        <template #orientation>
            Bachelor & Master
        </template>
        <template #speciality>
            Culinary
        </template>
    </Obzor>


    <section v-if="currentSection === 'programs'" ref="programs" class="programs">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 title-main">
                            <h1>Программы</h1>
                        </div>
                    </div>
                    
                    <div class="row program-select-wrapper">
                        <div class="col-12 col-lg-4">
                            <select v-model="program" ref="programSelector" class="form-select form-select-lg">
                                <option disabled value="">Выбрать программу</option>
                                <option value="gd">Grand Diplôme (Cuisine & Pastry)</option>
                                <option value="gdi">Grand Diplôme with Internship Pathway</option>
                                <option value="cd">Cuisine Diploma</option>
                                <option value="pd">Pastry Diploma</option>
                                <option value="bd">Boulangerie Diploma</option>
                                <option value="bb">Bachelor of Business</option>
                                <option value="wmp">Wine and Management programme</option>
                                <option value="mba">MBA in International Hospitality</option>
                                <option value="dg">Diploma in Gastronomy</option>
                            </select>
                        </div>
                    </div>
                    
                    <Program :url="''" v-if="this.program === 'gd'" id="gd">
                        <template #title>
                            BACHELOR IN CULINARY ARTS
                        </template>
                        <template #description>
                            Эта кулинарная программа представляет собой наиболее тщательный и полный курс обучения традиционным французским кулинарным приемам. Менее чем через год студенты могут получить прославленный Grand Diplôme®. Студенты, несмотря на прошлый опыт, должны начинать с базового уровня, чтобы перейти на следующий уровень. Студенты должны сдать письменные и практические экзамены, чтобы получить сертификаты Basic, Intermediate и Superior. Все три уровня по каждой дисциплине должны быть успешно пройдены до получения диплома.
                            Grand Diplôme® - это самая обширная программа обучения, доступная в Le Cordon Bleu. Программа сочетает в себе уроки кулинарии и кондитерского дела.
                            Эта программа обучения проводится поварами-кулинарами и кондитерами, работавшими по всему миру. Эта двойная программа обучения охватывает все методы французской гастрономии, необходимые для работы со множеством ингредиентов, и способы их адаптации к блюдам мировой кухни. Программы регулярно обновляются с учетом последних тенденций. Институт Le Cordon Bleu в Париже обеспечивает отличную среду для обучения благодаря своему ультра современному оборудованию, постоянно меняющимся учебным материалам и темпам уроков, что позволяет студентам достичь этого уровня всего за 9 месяцев.
                        </template>
                        <template #duree>
                            9 месяцев
                        </template>
                        <template #language>
                            французский и английский.
                        </template>
                        <template #cost>
                            48,700 евро (включая униформу и оборудование)
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Январь, Апрель, Июль, Октябрь.
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'gdi'" id="gdi">
                        <template #title>
                            Grand Diplôme with Internship Pathway 
                        </template>
                        <template #description>
                            CuGrand Diplôme® с программой Internship Pathway - это 18-месячная программа, ориентированная на обучение кулинарным методам, подкрепленная стажировкой - практическим опытом в профессиональном мире.
                            <br>
                            По окончании программы и после успешного прохождения практики и профессиональной стажировки, вы получите Le Cordon Bleu Grand Diplôme® и сертификат Internship Pathway.
                        </template>
                        <template #duree>
                            16 месяцев.
                        </template>
                        <template #language>
                            английский или французский
                        </template>
                        <template #cost>
                            51,200 евро (включая униформу и оборудование).
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                             Январь, Апрель, Июль, Октябрь.
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'cd'" id="cd">
                        <template #title>
                            Cuisine Diploma
                        </template>
                        <template #description>
                            Диплом по кулинарному искусству - это комплексная программа обучения, которая состоит из трех сертификатов: базового, среднего и высшего. Это образование высокого уровня позволяет постепенно изучать классические и современные французские кулинарные техники.
                        </template>
                        <template #duree>
                            9 месяцев (полная программа) или 3 месяца (одна ступень).
                        </template>
                        <template #language>
                            английский или французский
                        </template>
                        <template #cost>
                            10.900-29.000 евро 
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Январь, Апрель, Июль, Октябрь.
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'pd'" id="pd">
                        <template #title>
                            Pastry Diploma
                        </template>
                        <template #description>
                            Диплом по кондитерскому искусству фокусируется на постепенном изучении основных методов традиционной французской выпечки. Он состоит из трех сертификатов: базового, среднего и высшего. Студенты учатся осваивать самые сложные современные десерты.
                        </template>
                        <template #duree>
                            9 месяцев (полная программа) или 3 месяца (одна ступень).
                        </template>
                        <template #language>
                            английский или французский
                        </template>
                        <template #cost>
                            9.000-23.350 евро 
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Январь, Апрель, Июль, Октябрь.
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'bd'" id="bd">
                        <template #title>
                            Boulangerie Diploma
                        </template>
                        <template #description>
                            Диплом Boulangerie, разработанный Le Cordon Bleu Paris, обучает будущих профессионалов и предпринимателей основным методам изготовления выпечки, а также новым тенденциям в этой области.
                            Программа обучения состоит в основном из практических занятий. Она разделена на два уровня: базовый и продвинутый.
                        </template>
                        <template #duree>
                            3 месяца
                        </template>
                        <template #language>
                            английский или французский
                        </template>
                        <template #cost>
                            13.500 евро.
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Январь, Апрель, Июль, Октябрь.
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'bb'" id="bb">
                        <template #title>
                            Bachelor of Business
                        </template>
                        <template #description>
                            Бакалавр бизнеса в области кулинарного искусства/ гостиничного менеджмента - это двойная степень, созданная совместно Университетом Париж-Дофин и Le Cordon Bleu Paris. За 3 года она предлагает отличную подготовку в области гастрономии, гостиничного менеджмента и французского savoir-vivre, чтобы продвинуть французских и иностранных студентов к высшей управленческой карьере в ресторанной индустрии и индустрии гостеприимства.
                        </template>
                        <template #duree>
                            3 года
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            14.000 евро/год.
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Сентябрь
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'wmp'" id="wmp">
                        <template #title>
                            Wine and Management programme
                        </template>
                        <template #description>
                            Эта программа дает студентам двойной диплом по специальности «Винный менеджмент» Парижского института Le Cordon Bleu, открывающий широкое разнообразие профессий в этом секторе, а также в партнерстве с престижным Университетом Реймса Шампань-Арденны (URCA), диплом «Наследие винной культуры и туризм».
                        </template>
                        <template #duree>
                            10 месяцев
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            20.950 евро/год.
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Сентябрь
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'mba'" id="mba">
                        <template #title>
                            MBA in International Hospitality and Culinary Leadership
                        </template>
                        <template #description>
                            Le Cordon Bleu Paris и Paris Dauphine - Университет PSL объединились, чтобы предложить степень MBA в области международного гостеприимства и кулинарного лидерства.
                            Студенты, изучающие этот MBA, разовьют свои знания в секторах гостеприимства, гастрономии, лидерства и менеджмента.
                        </template>
                        <template #duree>
                            10 месяцев
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            27.000 евро/год.
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Сентябрь
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'dg'" id="dg">
                        <template #title>
                            Diploma in Gastronomy, Nutrition & Food Trends
                        </template>
                        <template #description>
                            Диплом в области гастрономии, питания и пищевых тенденций - это трехмесячная программа, специально разработанная Le Cordon Bleu в ответ на растущий спрос на вопросы здоровья, связанные с пищевыми продуктами.
                        </template>
                        <template #duree>
                             3 месяца
                        </template>
                        <template #language>
                            французский и английский
                        </template>
                        <template #cost>
                            7.500 евро
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                    </Program>
                </div>
            </div>
        </div>
    </section>

    <CampusMain v-if="currentSection === 'campus'" ref="campus" class="campus">
    </CampusMain>

</template>
<script>
import Program from '@/components/schools/slots/Program.vue'
import CampusMain from '@/components/schools/slots/CampusMain.vue'
import SectionsNav from '@/components/schools/slots/SectionsNav.vue'
import Obzor from '@/components/schools/slots/Obzor.vue'
import Campus from '@/components/schools/slots/Campus.vue'

export default {
    data(){
        return {
            currentSection:"presentation",
            program : ""
        }
    },
    components:{
        Program,
        CampusMain,
        SectionsNav,
        Obzor,
        Campus
    },
    methods:{
        showSection(section){
            this.currentSection = section;
            this.desktopScroll();
        },
        desktopScroll(){
            let scrollToElement = document.querySelector(".menu")
            scrollToElement.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }
}
</script>