<template>
    <Introduction :url="'/brochure/cordon'" :class="'introduction-cordon'">  
        <template #title>
            Le Cordon Bleu
        </template>
        <template #speciality>
            кулинария и кондитерское искусство
        </template>
        <template #min-duration>
            От 3 месяцев
        </template>
        <template #cost>
            От 9.000€
        </template>
        <template #campus>
            Париж
        </template>
    </Introduction>  
</template>
<script>
import Introduction from '@/components/schools/slots/Introduction.vue'
export default {
    components:{
        Introduction
    }
}
</script>